import myhttp from './http'

//获取听证会列表
export function getAllHearing(info,page,pageSize) {
    return myhttp({
        url: "/hring/selHringByProStartInfotwo",
        method: "POST",
        data: info,
        params:{page,pageSize}
    });
}
//获取一个听证会详细信息
export function getHearingInfoById(id) {
    return myhttp({
        url: "/hring/selectHringByidtwo",
        method: "POST",
        params: { id }
    });
}

//获取一个听证会听证员信息
export function getHearingExpertInfo(hringinfoid,page,pageSize) {
    return myhttp({
        url: "/hringattend/selectByProid",
        method: "POST",
        params: { hringinfoid,page,pageSize }
    });
}





//查询统计听证项目
export function getQueryStatsHearing(info,page,pageSize) {
    return myhttp({
        url: "/hring/hringstacallisttwo",
        method: "POST",
        data: info,
        params:{page,pageSize}
    });
}

//查询统计专家
export function getQueryStatsUser(info) {
    return myhttp({
        url: "/user/OfficerListBg",
        method: "POST",
        data: info
    });
}


//搜索专家
export function getQueryStatsUserSearch(name) {
    return myhttp({
        url: "/QueryStats/getQueryStatsUserSearch",
        method: "GET",
        params: { name }
    });
}
//查询统计听证项目
export function getQueryStatsHearingSearch(info) {
    return myhttp({
        url: "/QueryStats/getQueryStatsHearingSearch",
        method: "POST",
        data: info
    });
}


//搜索听证会
export function fuzzySearchHearing(info) {
    return myhttp({
        url: "/Hearing/fuzzySearchHearing",
        method: "POST",
        data: info
    });
}
