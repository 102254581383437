<template>
  <div class="statist_warp">
    <div class="main_top">
      <div style="display:flex;">
        <div class="block">
          <el-select
          v-model="procuratorateid"
          placeholder="请选择检察院名称"
          style="width: 220px"
        >
          <el-option
            v-for="item in proList"
            :key="item.id"
            :value="item.id"
            :label="item.procuratoratename"
            :disabled="disabled"

          ></el-option>
        </el-select>
          <el-date-picker
            v-model="value_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <el-input
          v-model="selectTxt"
          placeholder="请输入听证项目搜索"
          style="width: 220px; margin-left: 10px"
          class="select-actName"
        ></el-input>

        <el-button
          slot="reference"
          class="style_button style_button_Sel"
          icon="el-icon-search"
          @click="activitySelect"
          >搜索</el-button
        >
      </div>
         <el-button
            class="style_button"
            style="background: white; color: #044bfe; border: 1px solid #044bfe"
            type="text"
            @click="handleExport()"
            >导出</el-button
          >
    </div>
    <el-table :data="tableData" style="width: 100%" height="590">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="听证员性别比例">
              <span>{{ props.row.maleusercount }}：{{ props.row.femaleusercount }}</span>
            </el-form-item>
            <el-form-item label="检察院">
              <span>{{ props.row.procuratorate.procuratoratename }}</span>
            </el-form-item>
            <el-form-item label="参与率">
              <span
                >{{ props.row.participatecount }}</span
              >
            </el-form-item>
            <el-form-item label="专业领域">
              <span>{{ props.row.professional }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="听证项目" prop="hringname"> </el-table-column>
      <el-table-column label="听证时间" prop="hringdate"> </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";

import {
  getQueryStatsHearing,
} from "@/api/hearing";
import {
  getProInfoList
} from "@/api/system";
import { downloadFile } from "@/util/formtime";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      value: [],
      perList: [],
      tableData: [],
      proList:[],
      disabled:false,
      total: 0, //总条数
      page: 1, //页数
      pageSize: 10, //每页限制
      currentPage: 1, //当前页数
      options_name: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "待确认",
        },
        {
          value: "2",
          label: "已确认",
        },
        {
          value: "3",
          label: "已完成",
        },
      ],
      selectTxt: "", //搜索当前项
      value_time: null, //搜索时间
      value_name: "", //搜索状态
      procuratorateid: null,
      hearInfo: [{}], //听证详情
      expertList: [], //专家列表
      dialogFormVisible: false,
      formLabelWidth: "75px",
    };
  },
  mounted() {
    this.getproList();
     this.typeid = JSON.parse(
      window.localStorage.getItem("tltz_userinfo")
    ).types.id;
    if (this.typeid == 6) {
      this.procuratorateid = -1;
    } else {
      this.procuratorateid = JSON.parse(
        window.localStorage.getItem("tltz_userinfo")
      ).procuratorate.id;
      this.disabled = true
      
    }
    this._getQueryStatsHearing();
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    // console.log(this.perList);
  },
  methods: {
     dateFormat(dateData) {
      var date = new Date(dateData)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    //获取列表
    getproList() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        this.proList = data.data.list;
        this.proList.unshift({
          id: -1,
          procuratoratename: '全部'
        })
      });
    },
    //获取活动列表
    _getQueryStatsHearing() {
       const hringStatisticalBo = {
        procuratorateid: this.procuratorateid,
      }
      if( this.value_time !== null ){
        // info.starttime = this.value_time[0]
        // info.endtime =this.value_time[1]
        hringStatisticalBo.starttime = this.dateFormat(this.value_time[0])
        hringStatisticalBo.endtime =this.dateFormat(this.value_time[1])
      }else{
        hringStatisticalBo.starttime = ''
        hringStatisticalBo.endtime = ''
      }
      if( this.selectTxt !== '' ){
        hringStatisticalBo.hringname = this.selectTxt
      }
      getQueryStatsHearing(hringStatisticalBo,this.page,this.pageSize).then((data) => {
        // console.log(data);
        if (data.data == null) {
          this.page = 1;
           this.tableData = [];
          Message.error(data.msg);
          return;
        } else {
          data.data.list.forEach(item => {
            if(item.professionallist.length == 0){
              item.professional = '无要求'
            }else{
              let str = '';
              item.professionallist.forEach(item =>{
                str += item.professional + '*' + item.count + ';';
              })
              str = str.slice(0, str.length - 1)
              console.log(str)
              item.professional = str
            }
            
          });
          this.tableData = data.data.list;
          this.total = data.data.total;
          this.currentPage = data.data.pageNum;
          this.states = this.tableData;
        }
      });
    },
    //搜索
    activitySelect() {
      this._getQueryStatsHearing()
      // let res = this.perList.filter((val) => val == 22);
      // console.log(res)
      // if(!res.length){
      //   Message.error('您没有这个权限');
        
      // }else{
      // let name = this.selectTxt;
      // this.page = 1;
      // let startTime = null;
      // let endTime = null;
      // if (this.value_time !== null) {
      //   startTime = this.value_time[0];
      //   endTime = this.value_time[1];
      // }
      // let page = this.page;
      // let info = { endTime, name, page, startTime };
      // getQueryStatsHearingSearch(info).then((data) => {
      //   if (data.data == null) {
      //     Message.warning("查询内容不存在！");
      //   } else {
      //     this.tableData = data.data.list;
      //     this.total = data.data.total;
      //     this.currentPage = data.data.pageNum;
      //   }
      // });
      // }
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    //导出
    handleExport() {
      let res = this.perList.filter((val) => val == 75);
      // console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      downloadFile(BASE_URL + `/Excel/sheetSExportAllExcel`);
      }
    },
    //分页器
    handleCurrentChange(val) {
      this.page = val;
      // console.log(this.selectTxt);
      // if (this.selectTxt !== "") {
      //   this.activitySelect();
      //   return;
      // }
      this._getQueryStatsHearing();
    },
  },
};
</script>
<style lang='less' >
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.statist_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 54px 13px;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .select-actName {
      margin-right: 10px;
    }
    .el-select {
      margin-right: 10px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 400px;
    }
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #044bfe;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    margin-left: 10px;
  }
  .hearContent {
    margin-bottom: 30px;
    .hearTitle {
      font-size: 16px;
    }
  }

  .el-table {
    margin-top: 15px;
  }
  .el-pagination {
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
  .cell {
    // display: inline-block;
    // padding: 0;
    text-align: center;
  }
  .el-form {
    display: flex;
    justify-content: space-between;
    margin-right: 200px;
  }
  .el-table__expanded-cell[class*="cell"] {
    padding: 10px 50px;
  }
  .el-form-item__label,
  .el-form-item__content {
    line-height: 28px;
  }

  .demo-table-expand label {
    width: auto;
  }
  .demo-table-expand .el-form-item {
    width: auto;
  }
}
.el-table td,
.el-table th {
  padding: 15px 0;
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
</style>
